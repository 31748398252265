<template>
  <div class="activity-data">
    <!-- <div class="tips">
      <i class="el-icon-warning" style="color: #07D14A;margin-right:10px"></i>
      统计数据只包含已提交人数，未提交不纳入统计。
    </div> -->
    <div class="form-container">
      <div class="form-inline flex-between" style="">
        <!-- <div class="data-count" id="data-count"></div> -->
        <div></div>
        <el-form :inline="true" class="demo-form-inline">
          <div class="formInline-right flex-align-right csp">
            <div class="form-inline-item flex-align" @click="exportData">
              <i class="el-icon-folder-opened"></i>
              <span>导出数据</span>
            </div>
            <div class="form-inline-item flex-align" @click="batchPacks" v-loading.fullscreen.lock="fullscreenLoading"
              element-loading-text="打包中">
              <i class="el-icon-folder-opened"></i>
              <span>批量打包</span>
            </div>
            <div class="form-inline-item flex-align" @click="downloadPackTask">
              <img src="@/assets/download.png" style="width:20px;height:17px;margin-right:8px" />
              <span>下载打包</span>
            </div>
          </div>
        </el-form>
      </div>
      <div class="table_section">
        <el-table :data="tableData" border style="width: 100%" stripe>
          <el-table-column label="序号" width="100" type="index" align="center" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="userName" label="姓名" width="100" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机号" width="150" align="center">
          </el-table-column>
          <el-table-column prop="area" label="地区" width="120" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="学校" width="150" align="center">
          </el-table-column>
          <el-table-column prop="gradeName" label="年级" width="160" align="center">
            <template slot-scope="scope">
              {{scope.row.gradeName? scope.row.gradeName: '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="className" label="班级" width="100" align="center">
            <template slot-scope="scope">
              {{scope.row.className }}{{scope.row.className? '班' : '--'}}
            </template>
          </el-table-column>
          <el-table-column prop="roleName" label="角色" width="150" align="center">
          </el-table-column>
          <el-table-column prop="commitStatus" label="正式比赛" width="150" align="center" v-if="typeId">
          </el-table-column>
          <el-table-column prop="simulateCount" label="模拟次数" width="150" align="center" v-else>
          </el-table-column>
          <el-table-column prop="answerLength" label="正式比赛时长" width="150" align="center" v-if="typeId">
            <template slot-scope="scope">
              {{parseInt(scope.row.answerLength/60)}}′{{parseInt(scope.row.answerLength%60)}}″
            </template>
          </el-table-column>
          <el-table-column prop="avgAnswerLength" label="平均模拟时长" width="150" align="center" v-else>
            <template slot-scope="scope">
              {{parseInt(scope.row.avgAnswerLength/60)}}′{{parseInt(scope.row.avgAnswerLength%60)}}″
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
          :limit.sync="pages.pageSize" @pagination="handlePageChange" :layout="'prev, pager, next, ->, total'">
        </pagination>
      </div>
      <!-- 下载作业弹出框 -->
      <el-dialog title="打包作业列表" :visible.sync="dialogTableVisible">
        <el-table :data="packageList">
          <el-table-column property="filename" label="作业名称"></el-table-column>
          <el-table-column property="time" label="打包时间" width="150"></el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="text" v-if="scope.row.identifier"
                @click="handleEdit(scope.$index, scope.row)">下载</el-button>
              <el-button size="mini" type="text" disabled v-else @click="handleEdit(scope.$index, scope.row)">下载
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    props: {
      // work: {
      //   type: Object,
      //   default: function () {
      //     return {
      //       message: 'hello'
      //     }
      //   }
      // },
      stageId: Number, //活动环节ID
      typeId: Number, //活动类型ID
      gradeId: Number, //年级ID
      roleId: Number, //角色ID
    },
    data() {
      return {
        tableData: [],
        pages: { //分页
          currentPage: 1,
          pageSize: 10,
          total: 0,
        },
        dialogTableVisible: false,
        fullscreenLoading: false,
        packageList: [], //打包作业列表
        packFileKey: null, // 打包数据地址
        checkInterval: null,
        timerCount: 0,
      };
    },
    watch: {
      stageId(newVal, oldVal) {
        console.log('活动环节ID', newVal);
        if (!newVal || !oldVal) return;
        this.getMatchData();
      },
      typeId(newVal, oldVal) {
        console.log('活动类型ID', oldVal);
        // if (!newVal || !oldVal) return;
        this.getMatchData();
      },
      gradeId(newVal, oldVal) {
        console.log('年级ID', newVal);
        if (!newVal || !oldVal) return;
        this.getMatchData();
      },
      roleId(newVal, oldVal) {
        console.log('角色ID', newVal);
        if (!newVal) return;
        this.getMatchData();
      },
    },
    methods: {
      indexMethod(index) {
        return this.pages.currentPage > 1 ?
          (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
          index + 1
      },
      initEchart() {
        this.myChart = this.$echarts.init(document.getElementById("data-count"));
        let submitRate = ((this.work.answerMembers / this.work.totalMembers) * 100).toFixed(1)
        window.addEventListener('resize', () => {
          this.myChart.resize()
        })
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            right: '0%',
            top: 'center',
            orient: "vertical",
            icon: 'circle',
            textStyle: {
              fontSize: 12
            },
          },
          series: [{
            name: '访问来源',
            type: 'pie',
            left: '-40%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              position: 'center',
              formatter: `提交比例${isNaN(submitRate) ? 0 : submitRate}%`,
            },
            labelLine: {
              show: false
            },
            data: [{
                value: this.work.answerMembers || 0,
                name: `已提交`,
                itemStyle: {
                  color: '#FFB822'
                },
              },
              {
                value: (this.work.totalMembers - this.work.answerMembers) || 0,
                name: `未提交`,
                itemStyle: {
                  color: '#508EF9'
                },
              },
            ]
          }]
        };
        this.myChart.setOption(option);
      },
      // showDialog() {
      //   this.dialogTableVisible = true;
      //   this.downloadPackTask()
      // },
      handleEdit(index, row) {
        console.log(index, row)
        window.location.href = this.downloadURL + row.identifier
      },
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        // console.log(this.pages);
        this.getMatchData();
      },
      // async getWorkData() {
      //   let params = {
      //     questId: this.work.id,
      //     page: this.pages.currentPage,
      //     limit: this.pages.pageSize,
      //   };
      //   if (!this.work.id) return;
      //   let resData = await this.$Api.Form.getWorkData(params);
      //   console.log(resData);
      //   if (JSON.stringify(resData.data) == '[]' || resData.code !== 200) return;
      //   this.tableData = resData.data.list;
      //   this.pages.total = resData.data.totalCount;
      //   this.tableData.forEach((e, index) => {
      //     e.indexnum = index + 1;
      //     e.submitStatus = e.createTime ? "已提交" : "未提交";
      //   });
      // },
      exportData() {
        if (JSON.stringify(this.tableData) == '[]') {
          this.$message({
            message: '暂无需要导出的数据',
            type: 'error'
          })
          return
        }
        window.location.href =
          `${axios.defaults.baseURL}/course/activity/activityData/export?stageId=${this.stageId}&gradeId=${this.gradeId}&roleId=${this.roleId}&answerType=${this.typeId}`
      },
      batchPacks() {
        if (JSON.stringify(this.tableData) == '[]') {
          this.$message({
            message: '暂无需要打包的内容',
            type: 'error'
          })
          return
        }
        this.fullscreenLoading = true;
        let query = {
          stageId: this.stageId,
          gradeId: this.gradeId,
          roleId: this.roleId,
          answerType: this.typeId,
        }
        this.$Api.Activity.createStageData(query)
          .then(res => {
            console.log(res)
            // this.fullscreenLoading = false
            if (res.code == 200) {
              this.packFileKey = res.data
              this.checkInterval = setInterval(() => {
                this.checkPackFinish();
                this.timerCount++
              }, 1000)
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }

          })
          .catch(err => {
            console.log(err)
          })
      },
      async checkPackFinish() {
        let query = {
          stageId: this.stageId,
          gradeId: this.gradeId,
          roleId: this.roleId,
          answerType: this.typeId,
        }
        let resData = await this.$Api.Activity.isFinishCreate(query)
        console.log(resData.data)
        if (resData.data) {
          clearInterval(this.checkInterval);
          this.$message({
            message: '批量打包成功，点击右侧“下载打包”按钮，即可下载',
            type: 'success'
          })
          this.fullscreenLoading = false
        }
        if (!resData.data && this.timerCount > 60 * 10) {
          clearInterval(this.checkInterval);
          this.fullscreenLoading = false
          this.$alert('打包失败', {
            confirmButtonText: '确定',
          });
        }

      },
      downloadPackTask() {
        if (!this.packFileKey) {
          this.$message({
            message: '请先批量打包后，再进行下载。',
            type: 'error'
          })
          return;
        }
        window.location.href =
          `${axios.defaults.baseURL}/quest/qb/download/export?key=${this.packFileKey}`
        // let query = {
        //   key: this.packFileKey
        // }
        // this.$Api.Activity.downloadPackData(query)
        //   .then(res => {
        //     console.log("打包列表--", res)
        //     // this.packageList = res.data
        //   })
        //   .catch(err => {
        //     console.log(erss)
        //   })

      },

      async getMatchData() {
        this.packFileKey = null;
        this.timerCount = 0;
        let params = {
          stageId: this.stageId,
          gradeId: this.gradeId,
          roleId: this.roleId,
          answerType: this.typeId,
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        };
        let resData = await this.$Api.Activity.getMatchData(params);
        console.log(resData)
        this.tableData = resData.data.list
        this.pages.total = resData.data.totalCount;
      },
    },
    mounted() {
      console.log(123);
      this.getMatchData();
      // this.getWorkData();
      // this.initEchart();
    },
  };
</script>

<style lang="less" scoped>
  .activity-data {
    box-sizing: border-box;
    margin-bottom: 50px;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
    padding: 35px 46px 50px;
    background-color: #fff;

    .data-count {
      width: 270px;
      height: 200px;
    }

    .tips {
      background: rgba(7, 209, 74, 0.07);
      border-radius: 3px;
      line-height: 36px;
      padding-left: 13px;
      color: #666
    }

    .form-inline {
      padding: 0 0 0 40px;
      align-items: flex-end;
    }

    .form-filter {
      .fileter-item {
        margin-right: 20px;
        margin-bottom: 35px;
        color: #343434;
        text-align: center;

        p:first-child {
          font-size: 24px;
          font-weight: bold;
        }

        p:last-child {
          font-size: 18px;
        }
      }
    }

    .el-form-item__label {
      font-size: 16px;
      color: #595959;
    }

    .el-form-item {
      margin-right: 20px;

      .el-input__inner {
        border: 1px solid #858585;
        border-radius: 2px;
        color: #343434;
      }

      .el-input .el-select__caret {
        color: #343434;
      }

      .el-input__inner::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #343434;
      }

      .el-input__inner::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #343434;
      }

      .el-input__inner:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #343434;
      }
    }

    .el-form-item__content {
      .el-select {
        .el-input--suffix {
          max-width: 116px;

          .el-input__inner {
            height: 30px;
            line-height: 30px;
          }
        }
      }

      .el-input {
        width: 260px;

        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }
    }

    .formInline-right {
      margin-bottom: 20px;
    }

    .form-inline-item {
      margin-right: 22px;

      &:hover {
        opacity: .8;
      }


      &:last-child {
        margin-right: 0;
      }

      i {
        vertical-align: sub;
        margin-right: 8px;
        font-size: 20px;
        // font-weight: bold;
        color: #508EF9;


      }

      span {
        font-size: 14px;
        color: #5a5a5a;
      }
    }
  }

  .el-pager {
    .number {
      height: 32px;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
      margin: 0 5px;
    }

    li.active {
      background: #508EF9;
      color: #fff;
    }

    & li.active+li {
      border-left: 1px solid #dfdfdf;
    }
  }

  .page_size {
    text-align: right;
    padding: 20px 0;
  }

  .table-section {
    margin-top: 30px;
  }


  .nodata {
    color: #eee;
  }

  /deep/.el-table--border td {
    border-right: 0;
  }

  /deep/ .el-table__body-wrapper {
    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(191, 191, 191, .5);
      border-radius: 15px;
    }
  }

  /deep/ .el-table {
    border-radius: 8px;
    border: 1px solid rgba(199, 199, 199, .43);
  }

  /deep/ .el-table__header {
    thead th {
      color: #000;
      font-weight: 400;
      background-color: RGBA(250, 251, 255, 1);
      border: 0;
    }
  }

  /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: rgba(243, 243, 244, 1);
  }
</style>