<template>
    <div class="upload-file">
        <!--  action="https://jsonplaceholder.typicode.com/posts/"  -->
        <el-upload class="upload-demo" action="" :on-preview="handlePreview" :http-request="submitFile"
            :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1" :on-exceed="handleExceed"
            :file-list="fileList" accept=".xlsx,.xls">
            <el-button size="mudium" type="primary">点击导入</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xlsx/xls文件</div>
        </el-upload>
        <!--   -->
    </div>
</template>

<script>
    export default {
        props: {
            stageId: {
                type: Number,
                default: undefined,
            },
        },
        data() {
            return {
                fileList: []
            }
        },
        watch: {
            stageId(newVal, oldVal) {
                console.log('stageId', newVal);
            }
        },
        computed: {},
        methods: {
            // 关闭右侧上传抽屉提示弹窗
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                        this.$emit('closeExport', false)
                    })
                    .catch(_ => {});

            },
            handleFileChange() {
                this.$emit('uploadFile', {
                    name: this.name,
                    fileList: this.fileList,
                    fileMD5List: this.fileMD5List,
                    // link: this.link
                });
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileList) {
                // ，共选择了 ${files.length + fileList.length} 个文件
                this.$message.warning(
                    `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            async submitFile(file) {
                console.log('fileList', file)
                // return;
                let params = {
                    file: file.file,
                    stageId: this.stageId,
                    isFromData: true,
                }
                let resData = await this.$Api.Activity.uploadUser(params);
                console.log('上传', resData)
            },
        },
        mounted() {
            console.log('mounted');
        },
        destroyed() {
            console.log('destroyed')
        },
    }
</script>

<style lang="less" scoped>
    .upload-file {
        /deep/ .el-button.upload {
            padding: 6px 20px;
        }


        .tips {
            margin-top: 3px;
            height: 40px;

            p.text {
                display: inline-block;
            }
        }

        .file-list {
            li {
                display: inline-block;
                line-height: 32px;

                margin: 13px 20px 5px 0;
                border-radius: 4px;
                padding: 0px 12px;

                color: #666;
                border: 1px solid rgba(204, 204, 204, .22);

                // background: rgba(204, 204, 204, 0.22);

                span.name {
                    display: inline-block;
                    line-height: 32px;
                    min-width: 60px;
                }

                .delete {
                    // background-image: url("~@/assets/images/course/closefile.png");
                    display: inline-block;

                    margin-left: 25px;
                    width: 10px;
                    height: 10px;



                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    vertical-align: -1px;
                }
            }
        }
    }
</style>