<template>
    <div class="activity-container">
        <el-form ref="form" :model="form" label-width="180px" :rules="rules">
            <!-- 名称 -->
            <el-form-item label="名称：" prop="name">
                <el-input v-model="form.name" maxlength="30" show-word-limit style="width:555px"></el-input>
            </el-form-item>
            <!-- 环节类型 -->
            <el-form-item label="模式：" prop="isOnline" required>
                <el-radio-group v-model="form.isOnline">
                    <el-radio :label="1">线上</el-radio>
                    <el-radio :label="0">线下</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- 子活动时间 -->
            <el-form-item label="子活动时间：" required prop="stageTime">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.stageTime"
                    :picker-options="stagePickerOptions" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" @change="handleStageTime">
                </el-date-picker>
            </el-form-item>
            <!-- 模拟时间 -->
            <el-form-item label="模拟时间：">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.simulateTime"
                    :disabled="simulateTimeDisable" :picker-options="pickerOptions" type="datetimerange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <!-- 正式比赛 -->
            <el-form-item label="正式比赛时间：">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.formalTime"
                    :disabled="formalTimeDisable" :picker-options="pickerOptions" type="datetimerange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <!-- 可作答次数 -->
            <div v-if="form.formalTime">
                <el-form-item label="正式比赛作答次数：" prop="examCount" required>
                    <el-input v-model="form.examCount" show-word-limit style="width:80px" :disabled="!form.formalTime"
                        oninput="if (value > 5)value = 5">
                    </el-input> 次
                </el-form-item>
                <!-- 成绩显示 -->
                <el-form-item label="成绩显示：" prop="resultType" required>
                    <el-radio-group v-model="form.resultType">
                        <el-radio :label="1">最高成绩</el-radio>
                        <el-radio :label="0" disabled>平均成绩</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
            <!-- 成绩查询时间 -->
            <el-form-item label="成绩查询时间：" required prop="queryTime">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.queryTime" :disabled="queryTimeDisable"
                    :picker-options="queryPickerOptions" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <div v-if="form.formalTime || form.simulateTime">
                <!-- 比赛时长 -->
                <el-form-item label="比赛时长：" prop="matchDuration" required>
                    <el-input v-model="form.matchDuration" type="number" style="width:80px" size="mini"
                        oninput="if(value>180)value=180"></el-input>
                    <span style="margin-left:10px">分钟</span>
                </el-form-item>
                <!-- 试卷规则 -->
                <el-form-item label="试卷规则："></el-form-item>
                <!-- 试题总量 必填 -->
                <el-form-item label-width="200px" label="试题总量：" required prop="testCount">
                    <el-input v-model="form.testCount" type="number" style="width:80px" size="mini"
                        oninput="if (value > 300) value = 300" @change="changeTestCount"></el-input>
                </el-form-item>
                <!-- 只要有一个就必须都填 如果不填 可以都不填 -->
                <!-- <el-form-item label-width="105px"> -->

                <el-form-item label="客观题：" label-width="80px" style="margin-left:120px;">
                    <el-row>
                        <el-col :span="5">
                            <el-form-item label-width="75px" label="单选题：" prop="singleCount" :error="quesError">
                                <el-input v-model="form.singleCount" type="number" style="width:80px" size="mini"
                                    @change="changeQuesCount('singleCount')" :disabled="quesCountDisable">
                                </el-input>
                            </el-form-item>
                            <!-- <span>单选题： </span>
                      <el-input v-model="form.singleCount" type="number" style="width:80px" size="mini"></el-input> -->
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label-width="85px" label="多选题：" prop="multipleCount" :error="quesError">
                                <el-input v-model="form.multipleCount" type="number" style="width:80px" size="mini"
                                    @change="changeQuesCount('multipleCount')" :disabled="quesCountDisable">
                                </el-input>
                            </el-form-item>
                            <!-- <span>多选题： </span>
                      <el-input v-model="form.multipleCount" type="number" style="width:80px" size="mini"></el-input> -->
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label-width="80px" label="判断题：" prop="judgeCount" :error="quesError">
                                <el-input v-model="form.judgeCount" type="number" style="width:80px" size="mini"
                                    @change="changeQuesCount('judgeCount')" :disabled="quesCountDisable">
                                </el-input>
                            </el-form-item>
                            <!-- <span>判断题： </span>
                      <el-input v-model="form.judgeCount" type="number" style="width:80px" size="mini"></el-input> -->
                        </el-col>
                        <!-- <el-col :span="4">
                        <el-form-item label-width="80px" label="主观题：" prop="subjectiveCount" :error="quesError">
                            <el-input v-model="form.subjectiveCount" type="number" style="width:80px" size="mini"
                                @change="changeQuesCount('subjectiveCount')" :disabled="quesCountDisable">
                            </el-input>
                        </el-form-item>
                    </el-col> -->
                    </el-row>
                </el-form-item>
                <el-form-item label-width="202px" label="考察能力：" required>
                    <el-radio-group v-model="form.abilityType">
                        <el-radio :label="0">比例</el-radio>
                        <el-radio :label="1" disabled>数量</el-radio>
                    </el-radio-group>
                    <span v-if="form.abilityType == 0" style="margin-left:20px">{{`（提示：考察能力总和须等于10）`}}</span>
                </el-form-item>
                <el-form-item label-width="200px">
                    <el-row>
                        <el-col :span="3">
                            <p class="abilityType-name">1. 提取信息<span class="colon">：</span></p>
                            <el-form-item label-width="0" prop="infoCount" required :error="propError">
                                <el-input v-model="form.infoCount" style="width:80px;margin-left:5px;" size="mini"
                                    @change="changePropCount('infoCount')"></el-input>
                                <span class="colon" style="margin-left:3px;">：</span>
                            </el-form-item>
                            <!-- <p class="abilityType-name">1. 信息提取<span class="colon">：</span></p>
                            <el-input v-model="form.infoCount" style="width:80px" size="mini"
                                @change="changePropCount('infoCount')"></el-input>
                            <span class="colon">：</span> -->
                        </el-col>
                        <el-col :span="3">
                            <p class="abilityType-name">2. 形成解释<span class="colon">：</span></p>
                            <el-form-item label-width="0" prop="inferenceCount" required :error="propError">
                                <el-input v-model="form.inferenceCount" style="width:80px;margin-left:5px;" size="mini"
                                    @change="changePropCount('inferenceCount')"></el-input>
                                <span class="colon" style="margin-left:3px;">：</span>
                            </el-form-item>
                            <!-- <el-input v-model="form.inferenceCount" style="width:80px" size="mini"
                                @change="changePropCount('inferenceCount')"></el-input>
                            <span class="colon">：</span> -->
                        </el-col>
                        <el-col :span="3">
                            <p class="abilityType-name">3. 整体感知<span class="colon">：</span></p>
                            <el-form-item label-width="0" prop="notesCount" required :error="propError">
                                <el-input v-model="form.notesCount" style="width:80px;margin-left:5px;" size="mini"
                                    @change="changePropCount('notesCount')"></el-input>
                                <span class="colon" style="margin-left:3px;">：</span>
                            </el-form-item>
                            <!-- <el-input v-model="form.notesCount" style="width:80px" size="mini"
                                @change="changePropCount('notesCount')"></el-input>
                            <span class="colon">：</span> -->
                        </el-col>
                        <el-col :span="3">
                            <p class="abilityType-name">4. 做出评价<span class="colon">：</span></p>
                            <el-form-item label-width="0" prop="reflectCount" required :error="propError">
                                <el-input v-model="form.reflectCount" style="width:80px;margin-left:5px;" size="mini"
                                    @change="changePropCount('reflectCount')"></el-input>
                                <span class="colon" style="margin-left:3px;">：</span>
                            </el-form-item>
                            <!-- <el-input v-model="form.reflectCount" style="width:80px" size="mini"
                                @change="changePropCount('reflectCount')"></el-input>
                            <span class="colon">：</span> -->
                        </el-col>
                        <el-col :span="3">
                            <p class="abilityType-name">5. 完成特定任务</p>
                            <el-form-item label-width="0" prop="produceCount" required :error="propError">
                                <el-input v-model="form.produceCount" style="width:80px;margin-left:5px;" size="mini"
                                    @change="changePropCount('produceCount')"></el-input>
                            </el-form-item>
                            <!-- <el-input v-model="form.produceCount" style="width:80px" size="mini"
                                @change="changePropCount('produceCount')"></el-input> -->
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-row>
                    <el-form-item label="主观题：" label-width="80px" style="margin-left:120px;">
                        <el-row>
                            <el-col :span="5">
                                <el-form-item label-width="85px" label="单子朗读：" prop="wordSingle"
                                    :error="subjectQuesError">
                                    <el-input v-model="form.wordSingle" type="number" style="width:80px" size="mini"
                                        @change="changeQuesCount('wordSingle')" :disabled="quesCountDisable">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label-width="85px" label="词语朗读：" prop="wordMultiple"
                                    :error="subjectQuesError">
                                    <el-input v-model="form.wordMultiple" type="number" style="width:80px" size="mini"
                                        @change="changeQuesCount('wordMultiple')" :disabled="quesCountDisable">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label-width="85px" label="段落朗读：" prop="wordParagraph"
                                    :error="subjectQuesError">
                                    <el-input v-model="form.wordParagraph" type="number" style="width:80px" size="mini"
                                        @change="changeQuesCount('wordParagraph')" :disabled="quesCountDisable">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label-width="85px" label="说话题：" prop="voiceCount"
                                    :error="subjectQuesError">
                                    <el-input v-model="form.voiceCount" type="number" style="width:80px" size="mini"
                                        @change="changeQuesCount('voiceCount')" :disabled="quesCountDisable">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label-width="85px" label="附件题：" prop="attachmentCount"
                                    :error="subjectQuesError">
                                    <el-input v-model="form.attachmentCount" type="number" style="width:80px"
                                        size="mini" @change="changeQuesCount('attachmentCount')"
                                        :disabled="quesCountDisable">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-row>
                <!-- </el-form-item> -->

                <!-- 试题范围 -->
                <el-row>
                    <el-form-item label="试题范围：" prop="gradeIds" required style="display:inline-block;margin-right:25px">
                        <el-select v-model="form.gradeIds" placeholder="选择年级，可多选" multiple filterable clearable
                            @change="changeGrade">
                            <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="0" prop="bookIds" required style="display:inline-block">
                        <el-select v-model="form.bookIds" placeholder="选择教材，可多选" multiple filterable clearable
                            :disabled="bookDisabled" @change="changeBook">
                            <el-option v-for="item in bookList" :key="item.value" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <i class="el-icon-plus" @click="addRange"></i>
                    <div class="flex-layout" v-for="(item,index) in counterList" :key="item">
                        <testRange @change="handleTestRange" :index="item" :info="otherBookList[index]"></testRange>
                        <span class="delete-range csp" @click.stop="deleteRange(index,item)">删除</span>
                    </div>
                </el-row>
            </div>
            <!-- 是否显示成绩查询 -->
            <el-form-item label="是否显示成绩查询：" prop="isShowQuery" required>
                <el-radio-group v-model="form.isShowQuery">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- 是否显示大赛报告 -->
            <el-form-item label="是否显示大赛报告：" prop="isShowReport" required>
                <el-radio-group v-model="form.isShowReport">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item class="el-form-item-btns">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import testRange from "./test_range.vue";
    export default {
        name: "addclass",
        props: {
            stageId: {
                type: Number
            }
        },
        data() {
            return {
                activeName: "first",
                form: {
                    name: '',
                    abilityType: 0,
                    isShowQuery: 1,
                    isShowReport: 1,
                    isOnline: 1,
                    gradeIds: [],
                    bookIds: [],
                    matchDuration: undefined,
                    testCount: undefined,
                    singleCount: undefined,
                    multipleCount: undefined,
                    judgeCount: undefined,
                    // subjectiveCount: undefined,
                    wordSingle: undefined,
                    wordMultiple: undefined,
                    wordParagraph: undefined,
                    voiceCount: undefined,
                    attachmentCount: undefined,
                    resultType: 1,
                    examCount: 1,
                },
                submitForm: {
                    name: undefined, // 活动名称
                    isOnline: 1, //活动模式 1线上 0 线下
                    isShowQuery: undefined, // 是否显示成绩查询 0 是 1 否
                    isShowReport: undefined, // 是否显示大赛报告0 是 1 否
                    gradeBookInfo: [], // 年级、书籍信息
                    matchDuration: undefined, // 考试时长
                    testCount: undefined, // 试题总量
                    resultType: undefined, // 成绩显示
                    examCount: undefined, // 可作答次数
                    matchRule: "11",
                },
                rules: {
                    name: [{
                        required: true,
                        message: "请输入活动名称",
                        trigger: "blur"
                    }],
                    stageTime: [{
                        required: true,
                        message: "请选择子活动时间",
                        trigger: "blur"
                    }],
                    queryTime: [{
                        required: true,
                        message: "请选择成绩查询时间",
                        trigger: "blur"
                    }],
                    matchDuration: {
                        required: true,
                        message: "请填写比赛时长",
                        trigger: ["blur", "change"]
                    },
                    testCount: {
                        required: true,
                        message: "请填写试题总量",
                        trigger: ["blur", "change"]
                    },
                    gradeIds: [{
                        required: true,
                        message: "请选择试题年级",
                        trigger: "change"
                    }],
                    bookIds: [{
                        required: true,
                        message: "请选择试题书籍",
                        trigger: "change"
                    }],
                    singleCount: {
                        required: false,
                        message: "请填写单选题数量",
                        trigger: ["blur", "change"]
                    },
                    multipleCount: {
                        required: false,
                        message: "请填写多选题数量",
                        trigger: ["blur", "change"]
                    },
                    judgeCount: {
                        required: false,
                        message: "请填写判断题数量",
                        trigger: ["blur", "change"]
                    },
                    subjectiveCount: {
                        required: false,
                        message: "请填写主观题数量",
                        trigger: ["blur", "change"]
                    },
                    infoCount: {
                        required: true,
                        message: "请填写能力比例",
                        trigger: ["blur", "change"]
                    },
                    inferenceCount: {
                        required: true,
                        message: "请填写能力比例",
                        trigger: ["blur", "change"]
                    },
                    notesCount: {
                        required: true,
                        message: "请填写能力比例",
                        trigger: ["blur", "change"]
                    },
                    reflectCount: {
                        required: true,
                        message: "请填写能力比例",
                        trigger: ["blur", "change"]
                    },
                    produceCount: {
                        required: true,
                        message: "请填写能力比例",
                        trigger: ["blur", "change"]
                    },
                },
                abilityList: [],
                gradeList: [], // 年级列表
                bookList: [], // 书籍列表
                bookDisabled: true, // 书籍是否禁用
                selectGradesList: [], // 被选中的年级信息列表
                selectbooksList: [], // 被选中的书籍信息列表
                // powerlist: [], // 能力标准
                stagePickerOptions: { //今 天以前（不含今天）禁用
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                simulateTimeDisable: true, // 模拟时间禁用
                formalTimeDisable: true, // 正式比赛时间禁用
                pickerOptions: {}, // 模拟时间、正式时间限制
                queryTimeDisable: true, // 查询时间禁用
                queryPickerOptions: {}, // 查询成绩时间限制
                quesCountDisable: true, // 试题数量禁用
                isQeusCount: false, // 是否填写了四种试题数量
                quesCounter: {
                    singleCount: 0,
                    multipleCount: 0,
                    judgeCount: 0,
                    // subjectiveCount: 0,
                    wordSingle: 0,
                    wordMultiple: 0,
                    wordParagraph: 0,
                    voiceCount: 0,
                    attachmentCount: 0,
                },
                propCounter: {
                    infoCount: undefined,
                    inferenceCount: undefined,
                    notesCount: undefined,
                    reflectCount: undefined,
                    produceCount: undefined,
                },
                quesError: '', //客观题试题数量错误提示
                subjectQuesError: '', //主观题试题数量错误提示
                propError: '', //考察能力错误提示
                counter: 0,
                counterList: [],
                otherBookList: [],
                selectBookMap: {},

            };
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            }),
        },
        components: {
            testRange
        },
        watch: {},
        methods: {
            closeDialog() {
                this.$emit('closeDialog', false)
            },
            handleTestRange(data) {
                // console.log('试题范围组件回传数据', data)
                this.selectBookMap[data.index] = data.data
                // console.log('selectMap', this.selectBookMap)
            },
            addRange() {
                this.counter++
                this.counterList.push(this.counter);
            },
            deleteRange(i, item) {
                this.counterList.splice(i, 1)
                delete this.selectBookMap[item]
                // console.log('selectMap', this.selectBookMap)
            },
            // 更改子活动时间
            handleStageTime(val) {
                // console.log(val);
                if (!val) {
                    this.form.queryTime = this.form.simulateTime = this.form.formalTime = null;
                    this.queryTimeDisable = this.simulateTimeDisable = this.formalTimeDisable = true;
                    return;
                }
                this.queryTimeDisable = this.simulateTimeDisable = this.formalTimeDisable = false;
                let that = this;
                this.$nextTick(() => {
                    // 查询时间限制设置
                    this.queryPickerOptions = {
                        disabledDate(time) {
                            let beginTime = that.dayjs(val[1]).valueOf()
                            return time.getTime() < beginTime;
                        }
                    }
                    // 正式/模拟时间限制设置
                    this.pickerOptions = {
                        disabledDate(time) {
                            let beginTime = that.dayjs(val[0]).valueOf()
                            let endTime = that.dayjs(val[1]).valueOf()
                            return time.getTime() < beginTime || time.getTime() > endTime;
                        }
                    }
                })
            },
            // 改变试题总量
            changeTestCount(val) {
                // console.log('试题总量', val)
                if (val) {
                    this.quesCountDisable = false;
                    return;
                }
                this.quesCountDisable = true;
            },
            // 改变题量
            changeQuesCount(type) {
                // console.log(type);
                // 题型总和要等于试题总量
                this.quesCounter[type] = Number(this.form[type]);

                // this.checkSumCount();

                // if (this.form[type]) {
                //     // 只要四个题型其中一个有值，那么四个题型都要变为必填项目
                //     this.rules.singleCount.required = true;
                //     this.rules.multipleCount.required = true;
                //     this.rules.judgeCount.required = true;
                //     this.rules.subjectiveCount.required = true;
                //     this.isQeusCount = true; //只要填写了一种一个试题数量 那么该值则为 true 否则 flase
                //     return;
                // }
                // if (!this.form[type]) {
                //     this.rules.singleCount.required = false;
                //     this.rules.multipleCount.required = false;
                //     this.rules.judgeCount.required = false;
                //     this.rules.subjectiveCount.required = false;
                //     this.isQeusCount = false;
                // }
            },
            changePropCount(type) {
                // console.log(type);
                // 考察能力总和 = 10
                this.propCounter[type] = Number(this.form[type]);
                // this.checkPropCount();
            },
            // 计算考察能力总和
            checkPropCount() {
                let sumCount = 0;
                let flag = false;
                for (const key in this.propCounter) {
                    flag = this.propCounter[key] == undefined ? false : true;
                }
                for (const key in this.propCounter) {
                    // if (!this.propCounter[key]) {
                    //     return;
                    // }
                    sumCount += this.propCounter[key]
                }
                // console.log("sumCount", sumCount);
                if (flag && sumCount != 10) {
                    this.$alert('考察能力总和必须等于10', '提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.propError = '请正确填写数值';
                        }
                    });
                    return true;
                } else {
                    this.propError = '';
                    return false;
                }
            },
            // 计算试题总数
            checkSumCount() {
                //计算总额
                let sumCount = 0;
                for (const key in this.quesCounter) {
                    sumCount += this.quesCounter[key] ? this.quesCounter[key] : 0
                }
                console.log(sumCount);
                if (!this.form.singleCount && !this.form.multipleCount && !this.form.judgeCount) {
                    if (sumCount <= this.form.testCount) {
                        // 题目总数 <= 试题总量 正确
                        return true;
                    } else {
                        // 题目总数 > 试题总量 错误给用户提示
                        this.$alert('主观题总数需小于填写的试题总量！', '提示', {
                            confirmButtonText: '确定',
                            callback: action => {
                                this.subjectQuesError = '请正确填写数值';
                            }
                        });
                        return false;
                    }
                }
                // 填写了 任意 客观题 数量
                if (this.form.singleCount || this.form.multipleCount || this.form.judgeCount) {
                    if (sumCount == this.form.testCount) {
                        // 题目总数 == 试题总量 正确
                        return true;
                    } else {
                        // 题目总数 != 试题总量 错误 给用户提示
                        this.$alert('填写的试题数量请保持与试题总量相等！', '提示', {
                            confirmButtonText: '确定',
                            callback: action => {
                                this.subjectQuesError = '请正确填写数值';
                                this.quesError = '请正确填写数值';
                            }
                        });
                        return false;
                    }
                }
                // let flag = false;
                // for (const key in this.quesCounter) {
                //     flag = this.quesCounter[key] ? true : false;
                // }
                // if (sumCount > this.form.testCount) {
                //     this.$alert('单选题、多选题、判断题、主观题总和不能大于试题总量！', '提示', {
                //         confirmButtonText: '确定',
                //         callback: action => {
                //             this.quesError = '请正确填写数值';
                //         }
                //     });
                //     return true;
                // } else if (flag && sumCount < this.form.testCount) {
                //     this.$alert('单选题、多选题、判断题、主观题总和不能小于试题总量！', '提示', {
                //         confirmButtonText: '确定',
                //         callback: action => {
                //             this.quesError = '请正确填写数值';
                //         }
                //     });
                // } else {
                //     this.quesError = '';
                //     return false;
                // }
            },
            // 更改试题范围中的年级
            changeGrade(val) {
                // console.log('选中的年级id', val);
                this.bookDisabled = true;
                if (val.length > 0) {
                    this.getSelectBookList();
                    this.bookDisabled = false;
                    this.selectGradesList = [];
                    val.map(item => {
                        let res = this.gradeList.find(ele => ele.id == item)
                        this.selectGradesList.push(res);
                    })
                    // console.log('已经选中的年级列表', this.selectGradesList)
                    return
                } else {
                    this.form.bookIds = [];
                    this.selectbooksList = [];
                    this.selectBooksList = [];
                }
            },
            // 更改试题范围中的教材
            changeBook(val) {
                // console.log('选中的书籍id', val);
                this.selectBooksList = [];
                if (val.length > 0) {
                    val.map(item => {
                        let res = this.bookList.find(ele => ele.id == item)
                        this.selectBooksList.push(res);
                    })
                    // console.log('已经选中的书籍列表', this.selectBooksList)
                }
            },
            // 验证并提交表单
            onSubmit() {
                // console.log(this.form);
                if (!this.checkSumCount()) {
                    return;
                }
                if (this.checkPropCount()) {
                    return;
                }
                this.$refs['form'].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    // 处理表单提交数据
                    for (const key in this.submitForm) {
                        if (this.form.hasOwnProperty(key)) {
                            if (key == 'matchDuration' || key == 'testCount') {
                                this.submitForm[key] = Number(this.form[key])
                            } else {
                                this.submitForm[key] = this.form[key]
                            }
                        }
                    }
                    // 子活动时间
                    this.$set(this.submitForm, 'stageStartTime', this.form.stageTime[0])
                    this.$set(this.submitForm, 'stageEndTime', this.form.stageTime[1])
                    // 查询时间
                    this.$set(this.submitForm, 'queryStartTime', this.form.queryTime[0])
                    this.$set(this.submitForm, 'queryEndTime', this.form.queryTime[1])
                    // 模拟时间
                    if (this.form.simulateTime) {
                        this.$set(this.submitForm, 'simulateStartTime', this.form.simulateTime[0])
                        this.$set(this.submitForm, 'simulateEndTime', this.form.simulateTime[1])
                    }
                    // 正式时间
                    if (this.form.formalTime) {
                        this.$set(this.submitForm, 'formalStartTime', this.form.formalTime[0])
                        this.$set(this.submitForm, 'formalEndTime', this.form.formalTime[1])
                    }
                    // 活动Id
                    // this.$set(this.submitForm, 'id', Number(this.$route.params.id))
                    this.$set(this.submitForm, 'id', this.stageId)
                    // 选题数量
                    for (const key in this.quesCounter) {
                        // 填写了数量的题目 提交
                        if (this.quesCounter[key]) {
                            this.$set(this.submitForm, key, Number(this.form[key]))
                        }
                        // 填写数量后又删除数量的，填写0
                        if (!this.quesCounter[key]) {
                            this.$set(this.submitForm, key, null)
                        }
                    }
                    // if (this.isQeusCount) {
                    //     this.$set(this.submitForm, 'singleCount', Number(this.form.singleCount))
                    //     this.$set(this.submitForm, 'multipleCount', Number(this.form.multipleCount))
                    //     this.$set(this.submitForm, 'judgeCount', Number(this.form.judgeCount))
                    //     // this.$set(this.submitForm, 'subjectiveCount', Number(this.form.subjectiveCount))
                    //     this.$set(this.submitForm, 'wordSingle', Number(this.form.wordSingle))
                    //     this.$set(this.submitForm, 'wordMultiple', Number(this.form.wordMultiple))
                    //     this.$set(this.submitForm, 'wordParagraph', Number(this.form.wordParagraph))
                    //     this.$set(this.submitForm, 'voiceCount', Number(this.form.voiceCount))
                    //     this.$set(this.submitForm, 'attachmentCount', Number(this.form.attachmentCount))
                    // }
                    // 能力标准
                    let contrastMap = {
                        infoCount: "提取信息",
                        inferenceCount: "形成解释",
                        notesCount: "整体感知",
                        reflectCount: "做出评价",
                        produceCount: "完成特定任务",
                    }
                    this.abilityList.map(item => {
                        for (const key in this.propCounter) {
                            if (item.name == contrastMap[key]) {
                                item.value = this.propCounter[key]
                            }
                        }
                    })
                    // console.log('能力标准', this.abilityList)
                    this.$set(this.submitForm, 'abilityProportion', JSON.stringify(this.abilityList))
                    // 书籍信息
                    // console.log('已经选中的年级列表', this.selectGradesList)
                    // console.log('已经选中的书籍列表', this.selectBooksList)
                    let gradeBookInfo = [];
                    this.selectGradesList.map(item => {
                        this.selectBooksList.map(ele => {
                            let bookInfo = {
                                bindGradeId: ele.gradeId,
                                gradeId: item.id,
                                bookId: ele.id,
                            }
                            gradeBookInfo.push(bookInfo);
                        })
                    })
                    let allBooke = [gradeBookInfo];
                    let keys = Object.keys(this.selectBookMap);
                    for (let i = 0; i < keys.length; i++) {
                        allBooke.push(this.selectBookMap[keys[i]]);
                    }
                    this.$set(this.submitForm, 'gradeBookInfo', allBooke)
                    // console.log('gradeBookInfo', allBooke);

                    this.commitForm();

                })
            },
            // 创建活动请求
            async commitForm() {
                console.log('submitForm', this.submitForm);
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                let resData = await this.$Api.Activity.editStageForMatch(this.submitForm)
                // console.log(resData);
                loading.close();
                if (resData.data.code == 200) {
                    this.$notify.success("编辑子活动成功")
                    this.closeDialog()
                } else {
                    this.$notify.error({
                        title: "编辑子活动失败",
                        message: resData.data.msg
                    })
                }

            },
            // 获取年级列表
            async getStageGrade() {
                let resData = await this.$Api.Activity.getStageGrade();
                // console.log('年级列表', resData)
                this.gradeList = resData.data;
            },
            // 获取年级对应书籍列表
            async getSelectBookList() {
                let data = {
                    gradeIds: this.form.gradeIds,
                    projectId: this.currentProject.id
                }
                let resData = await this.$Api.Activity.getSelectBookList(data);
                // console.log('书籍列表', resData)
                this.bookList = resData.data;
                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 能力环节标准
            async getStageAbilityList() {
                let resData = await this.$Api.Activity.getStageAbilityList();
                // console.log('环节能力标准', resData)
                this.abilityList = resData.data;
            },
            // 获取环节信息
            async getStageInfo() {
                // let resData = await this.$Api.Activity.getGameInfo(this.$route.params.id)
                let resData = await this.$Api.Activity.getGameInfo(this.stageId)
                // console.log('创建信息', resData);
                for (const key in this.form) {
                    // console.log(key);
                    if (resData.data.hasOwnProperty(key)) {
                        this.$set(this.form, key, resData.data[key]);
                    }
                }
                // console.log(this.form);
                // 试题数量赋值
                if (this.form.testCount) {
                    this.quesCountDisable = false;
                    for (const key in this.quesCounter) {
                        this.quesCounter[key] = this.form[key];
                    }
                }
                // 父级活动id
                // this.$set(this.submitForm, 'activityId', resData.data.activityId);
                this.$set(this.submitForm, 'activityId', this.$route.params.id);
                // console.log('题目计数器', this.quesCounter)
                // 时间赋值
                this.queryTimeDisable = false;
                this.$set(this.form, 'stageTime', [resData.data.stageStartTime, resData.data.stageEndTime])
                this.$set(this.form, 'queryTime', [resData.data.queryStartTime, resData.data.queryEndTime])
                // 正式，模拟时间限制
                this.formalTimeDisable = false;
                this.simulateTimeDisable = false;
                let that = this;
                this.pickerOptions = {
                    disabledDate(time) {
                        let beginTime = that.dayjs(that.form.stageTime[0]).valueOf()
                        let endTime = that.dayjs(that.form.stageTime[1]).valueOf()
                        return time.getTime() < beginTime || time.getTime() > endTime;
                    }
                }
                if (resData.data.simulateStartTime) {
                    // this.simulateTimeDisable = false;
                    this.$set(this.form, 'simulateTime', [resData.data.simulateStartTime, resData.data
                        .simulateEndTime
                    ])
                }
                if (resData.data.formalStartTime) {
                    // this.formalTimeDisable = false;
                    this.$set(this.form, 'formalTime', [resData.data.formalStartTime, resData.data.formalEndTime])
                }
                if (resData.data.matchDuration) {
                    this.$set(this.form, 'matchDuration', resData.data.matchDuration / 60)
                }
                // 能力标准
                let ability = JSON.parse(resData.data.abilityProportion);
                let contrastMap = {
                    infoCount: "提取信息",
                    inferenceCount: "形成解释",
                    notesCount: "整体感知",
                    reflectCount: "做出评价",
                    produceCount: "完成特定任务",
                }
                if (ability) {
                    ability.map(item => {
                        // console.log(item)
                        for (const key in this.propCounter) {
                            if (item.name == contrastMap[key]) {
                                this.propCounter[key] = item.value
                                this.$set(this.form, key, item.value)
                            }
                        }
                    })
                }
                // 书籍信息
                if (resData.data.gradeBookInfo.length > 0) {
                    // 存储一下回显的数量并记录到counter 
                    // 因为第一项不在组件 所以要从回显的数据 -1 
                    this.counter = resData.data.gradeBookInfo.length - 1;
                    for (let i = 1; i <= this.counter; i++) {
                        this.counterList.push(i);
                    }
                    // 书籍的数据处理
                    let gradeIds = []
                    let bookIds = []
                    resData.data.gradeBookInfo[0].map(item => {
                        if (!(gradeIds.find(ele => ele == item.gradeId))) {
                            gradeIds.push(item.gradeId);
                        }
                        if (!(bookIds.find(ele => ele == item.bookId))) {
                            bookIds.push(item.bookId);
                        }
                    })
                    this.$set(this.form, 'gradeIds', gradeIds);
                    this.changeGrade(gradeIds);
                    let selectBook = this.getSelectBookList();
                    Promise.all([selectBook]).then((values) => {
                        if (values.length > 0) {
                            this.bookDisabled = false;
                            this.$set(this.form, 'bookIds', bookIds);
                            this.changeBook(bookIds);
                        }
                    });
                    resData.data.gradeBookInfo.splice(0, 1)
                    this.otherBookList = resData.data.gradeBookInfo;
                    // console.log('其他书籍', this.otherBookList)

                }


            },
            // 页面初始化
            init() {
                this.getStageInfo();
                this.getStageGrade();
                this.getStageAbilityList();
            }
        },
        mounted() {
            // console.log('mounted', this.form)
            this.init()
        },
        beforeDestroy() {
            // console.log('destroyed')
            this.$refs['form'].resetFields();
        },
    };
</script>

<style lang="less" scoped>
    .activity-container {

        /* 取消input的上下箭头 */
        /deep/ input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
        }

        /deep/ input::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
        }

        /deep/ input[type="number"] {
            -moz-appearance: textfield;
        }

        .abilityType-name {
            padding-left: 5px;

            .colon {
                margin-left: 8px;
            }
        }

        .colon {
            margin-left: 3px;
        }

        @media screen and(min-width:1250px) {
            .abilityType-name .colon {
                margin-left: 24px;
            }

            .colon {
                margin-left: 20px;
            }

        }

        .el-icon-plus {
            color: #333;
            font-size: 14px;
            font-weight: 600;
            margin-left: 15px;
            cursor: pointer;
            padding: 2px;
            border: 1px solid transparent;
            border-radius: 50%;
            transition: 1s all;

            &:hover {
                border: 1px solid #333;
            }
        }

        .delete-range {
            color: #508EF9;
            margin-left: 17px;
            align-self: flex-start;
            margin-top: 10px;

            &:hover {
                color: rgba(80, 142, 249, .7);
            }
        }

    }
</style>