<template>
  <div class="activity-selection flex-layout">
    <div class="selection-work-wrapper">
      <div v-if="JSON.stringify(sutsList) !== '[]'">
        <div class="selected-baseInfo flex-align">
          <img :src="downloadURL + selectedStudent.avatar" alt="" class="base-avatar"
            :onerror="$store.state.user.defaultAvatar">
          <div>
            <p class="base-userName">{{selectedStudent.name}}</p>
            <p class="base-createTime">{{currentCheckInfo.createTime}}</p>
          </div>
        </div>
        <div class="selected-score-count">
          <div class="score-item" v-for="(item,index) in scoreMap" :key="index">
            <div class="score-num" v-if="index!='answerLength'">
              {{currentCheckInfo[index] || '0'}}<span v-if="index=='objectiveRightRate'">%</span>
            </div>
            <div class="score-num flex-layout" v-else>
              <p>{{currentCheckInfo.answerLength | transformTimeFormat('min')}}<span>分钟</span></p>
              <p>{{currentCheckInfo.answerLength | transformTimeFormat('second')}}<span>秒</span></p>
            </div>
            <div class="score-name">{{item.title}}</div>
          </div>
        </div>
        <div class="selected-correct flex-between">
          <div class="selected-correct-inner">

            <!--  v-for="(item, index) in extraList" :key="'q' + index"  @click="choosequest(item.id)"-->
            <!-- <div class="extra-file">
              <h5 class="extra-file-title">{{ handleQuestTitle(subjectiveInfo.title )|| '暂无题目'}}</h5>
              <p class="extra-file-explain" v-if="subjectiveInfo.explain">{{subjectiveInfo.explain}}</p>
              <div v-for="(items, index) in subjectiveInfo.subjectiveFiles" :key="'w' + index">
                <file :file="items.fileMd5+index" :md5="items.fileMd5" :fileInfo="items"></file>
              </div>
              <el-image v-for="(items, index) in subjectiveInfo.subjectiveImagesFiles" :key="'e' + index"
                class="extra-image" :src="downloadURL + items.fileMd5" :preview-src-list="subjectiveInfo.imageList">
              </el-image>

              <div class="fujian_item" v-for="(items, index) in subjectiveInfo.subjectiveVideoFiles" :key="`r${index}`">
                <VideoPlay :file="items.fileMd5 + index" :id="items.fileMd5"></VideoPlay>
              </div>
              <audio class="fujian_item" style="display:block;margin-top:10px"
                v-for="(items, index) in subjectiveInfo.subjectiveAudioFiles" :key="`a${index}`" controls>
                <source :src="downloadURL + items.fileMd5" type="audio/mpeg" />
                如果该元素不被浏览器支持，则本段文本被显示。
              </audio>
            </div> -->

            <div class="extra-file csp" v-for="(item, index) in subjectiveDtos" :key="'q' + index"
              @click="choosequest(item)" :class="{'activeQuest':item.questionId == currentQuest.questionId}">
              <h4 class="selected-correct-title">{{typeMap[item.type]}}：</h4>
              <h5 class="extra-file-title">{{ handleQuestTitle(item.title)|| '暂无题目'}}</h5>
              <p class="extra-file-explain" v-if="item.explain">{{subjectiveInfo.explain}}</p>
              <!-- 文件 -->
              <div v-for="(items, index) in item.subjectiveFiles" :key="'w' + index">
                <file :file="items.fileMd5+index" :md5="items.fileMd5" :fileInfo="items"></file>
              </div>
              <!-- 图片 -->
              <el-image v-for="(items, index) in item.subjectiveImagesFiles" :key="'e' + index" class="extra-image"
                :src="downloadURL + items.fileMd5" :preview-src-list="handleImageList(item.subjectiveImagesFiles)">
              </el-image>

              <!-- 视频 -->
              <div class="fujian_item" v-for="(items, index) in item.subjectiveVideoFiles" :key="`r${index}`">
                <VideoPlay :file="items.fileMd5 + index" :id="items.fileMd5"></VideoPlay>
              </div>
              <!-- 音频 -->
              <audio class="fujian_item" style="display:block;margin-top:10px"
                v-for="(items, index) in item.subjectiveAudioFiles" :key="`a${index}`" controls
                controlsList="nodownload">
                <source :src="downloadURL + items.fileMd5" type="audio/mpeg" />
                如果该元素不被浏览器支持，则本段文本被显示。
              </audio>
            </div>
          </div>
          <grade ref="check_right" :id="selectedQuestionId" :formid="formid" :power="powers" :firstScore="firstScore"
            :stuInfo="currentUser" @updateCheckInfo="updateCheckInfo" :key="currentQuest.questionId"
            :gradeDisable="currentQuest.type==5||currentQuest.type==6||currentQuest.type==7?true:false" />
        </div>
      </div>
      <Empty :show="sutsList && sutsList.length === 0" :text="'无需要批改的学员'"></Empty>
    </div>
    <div class="submit-list-wrapper">
      <div class="search-wrapper flex-align-between">
        <span>已交人数：{{pages.total}}</span>
        <el-input placeholder="搜索" suffix-icon="el-icon-search" class="search" v-model="userName" @change="searchUser">
        </el-input>
      </div>
      <div class="submit-list flex-column">
        <ul class="list-sort flex-layout">
          <li v-for="(item) in [{name:'全部',id:null},{name:'待批改',id:0},{name:'已批改',id:1}]" :key="item.id"
            :class="{'active':activeTab == item.id}" @click="changeSearchType(item)">
            {{item.name}}
          </li>
        </ul>
        <ul class="list-main">
          <li class="student-item csp flex-align-between" :class="{'activeSelected':selectedStudent.id == item.id}"
            v-for="(item, index) in sutsList" :key="'i' + index" @click="changeSelectedStudent(item)">
            <div class="student-userInfo flex-align">
              <span class="student-order wes">{{pages.page>1?(pages.size*(pages.page-1))+index+1:index+1}}</span>
              <img class="student-avatar" :src="downloadURL + item.avatar" :onerror="$store.state.user.defaultAvatar"
                alt />
              <span class="student-username wes">{{ item.name }}</span>
            </div>
            <span class="correcting-Status" :style="{'color':item.status == 0 ? '#2373ec':''}">
              {{item.status ? item.status == 1 ? '已批':'无需批' : '待批'}}
            </span>
          </li>
        </ul>
        <div class="pagination tc" v-if="sutsList && sutsList.length !== 0">
          <el-pagination background layout="prev, sizes, next" :total="pages.total" :page-size="pages.size"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 15, 20]">
          </el-pagination>
        </div>
        <Empty :show="sutsList && sutsList.length === 0" :text="'无需要批改的学员'"></Empty>
      </div>
    </div>
  </div>
</template>

<script>
  import grade from "./match/grade.vue"
  import file from "./match/file-display.vue"
  export default {
    props: {
      stageId: Number, //活动环节ID
      // typeId: Number, //活动类型ID
      gradeId: Number, //年级ID
      roleId: Number, //角色ID
    },
    components: {
      grade,
      file
    },
    data() {
      return {
        extraList: [{
          audiolist: [],
          explain: "",
          filelist: [{
              id: "87955912c275f5f066f29757e5c0654a_15638_xlsx",
              name: "幼儿教师专业发展标准.xlsx",
              type: "xlsx",
            },
            {
              id: "87955912c275f5f066f29757e5c0654a_15638_xlsx",
              name: "幼儿教师专业发展标准.xlsx",
              type: "xlsx",
            }
          ],
          id: "field101",
          imglist: [{
            id: "202203610958b5282ffb561f0550fdae_21759_jpg",
            name: "mmexport1617338651218.jpg",
            type: "jpg",
          }],
          powerVal: "把握学生起点,分析学生认知,正确理解课程内容,恰当评价学生表现",
          questionType: 0,
          srclist: [
            "https://wstestv2.lezhireading.com:30443/api/fu/front/upload/download?identifier=202203610958b5282ffb561f0550fdae_21759_jpg"
          ],
          title: "请根据本班学情，从如何使用信息技术设计匹配学习目标的评价方案入手，选择一篇议论文教学进行个人反思，并提交反思图。 支持二次编辑，支持查看他人作业",
          videolist: [{
            id: "e2b83ebfa9aa0208476c0e2b8434f15e_908124_mp4",
            name: "wx_camera_1600660228834.mp4",
            type: "mp4",
          }],
        }], //附件题数组
        subjectiveInfo: {},
        sutsList: [{
            content: "[{\"id\":\"field101\",\"explain\":\"\",\"result\":[{\"id\":\"202203610958b5282ffb561f0550fdae_21759_jpg\",\"type\":\"jpg\",\"name\":\"mmexport1617338651218.jpg\"},{\"id\":\"e2b83ebfa9aa0208476c0e2b8434f15e_908124_mp4\",\"type\":\"mp4\",\"name\":\"wx_camera_1600660228834.mp4\"}]}]",
            correctingStatus: 0,
            createTime: "2022-01-18 15:15:56",
            id: 26024,
            limit: 0,
            page: 0,
            questionnaireId: 595,
            userAvatar: "38c2368d799a5cd9bd6c4bb69e9fd38d_61911_jpg",
            userId: 3113,
            userName: "孙宏慧1"
          },
          {
            content: "[{\"id\":\"field101\",\"explain\":\"\",\"result\":[{\"id\":\"202203610958b5282ffb561f0550fdae_21759_jpg\",\"type\":\"jpg\",\"name\":\"mmexport1617338651218.jpg\"},{\"id\":\"e2b83ebfa9aa0208476c0e2b8434f15e_908124_mp4\",\"type\":\"mp4\",\"name\":\"wx_camera_1600660228834.mp4\"}]}]",
            correctingStatus: 0,
            createTime: "2022-01-18 15:15:56",
            id: 26024,
            limit: 0,
            page: 0,
            questionnaireId: 595,
            userAvatar: "38c2368d799a5cd9bd6c4bb69e9fd38d_61911_jpg",
            userId: 3112,
            userName: "孙宏慧1"
          }
        ],
        userName: null,
        selectedStudent: {},
        pages: {
          page: 1,
          size: 10,
          total: 0,
        },
        activeTab: null,
        currentCheckInfo: {
          answerDuration: "", //答题时长
          createTime: "", //学员问卷提交时间
          totalPoints: 0, //总得分
          objectiveScore: "", //客观题得分
          objectiveAccuracy: "", //客观题正确率
          subjectiveScore: "", //主观题得分
          commentInfo: "", //专家评论
          userStar: "", //学员星星
          questionCheck: [], //当前问题的评分与能力评星
        }, //当前学员的批改信息
        scoreMap: {
          //客观题得分
          objectiveRightRate: {
            title: '客观题正确率',
            value: ''
          },
          //客观题正确率
          subjectiveScore: {
            title: '主观题得分',
            value: ''
          }, //主观题得分
          answerLength: {
            title: '答题时长',
            value: ''
          },
          //答题时长
        },
        // 评分所用数据
        selectedQuestionId: null, //当前选中的题目ID
        formid: null, //当前题目所在的表单ID
        firstScore: 0, //第一题的得分（当前版本只有一题）
        currentUser: {}, //当前学生的信息
        powers: [],
        subjectiveDtos: [],
        currentQuest: {},
        typeMap: {
          1: '单选题',
          2: '多选题',
          3: '判断题',
          4: '填空题',
          5: '单字题',
          6: '多字题',
          7: '朗读题',
          8: '说话题',
          9: '附件题',
        }
      };
    },
    filters: {
      // 分钟转时分秒
      transformTimeFormat(value, type) {
        // console.log(value, type)
        if (!value) return '--';
        // let arrs = value.match(/\d{1,}/g);
        if (type == 'min') {
          return parseInt(value / 60);
        }
        if (type == 'second') {
          return value % 60
        }
      }
    },
    watch: {
      stageId(newVal, oldVal) {
        // console.log('活动环节ID', oldVal);
        if (!newVal || !oldVal) return;
        this.getWorkUserList();
      },
      // typeId(newVal, oldVal) {
      //   console.log('活动类型ID', oldVal);
      //   if (!newVal || !oldVal) return;
      //   this.getWorkUserList();
      // },
      gradeId(newVal, oldVal) {
        // console.log('年级ID', oldVal);
        if (!newVal || !oldVal) return;
        this.getWorkUserList();
      },
      roleId(newVal, oldVal) {
        // console.log('角色ID', oldVal);
        if (!newVal) return;
        this.getWorkUserList();
      },
    },
    methods: {
      // 处理题目有标签的情况
      handleQuestTitle(title) {
        if (!title) {
          return;
        }
        let result = title.match(/\[(IMG:|AUDIO:|VOICE:|VIDEO:).*\]/);
        // console.log("result", result);
        let str = result ? result[0] : null;
        if (str) {
          title = title.replace(str, '')
        }
        return title;
      },
      handleImageList(list) {
        let imageList = []
        list.map((item) => {
          imageList.push(this.downloadURL + item.fileMd5)
        })
        return imageList
      },
      handleCurrentChange() {
        this.getWorkUserList();
      },
      handleSizeChange() {
        this.pages.page = 1;
        this.getWorkUserList();
      },
      changeSelectedStudent(selected) {
        this.selectedStudent = selected
        this.selectedQuestionId = null;
        this.formid = null;
        this.firstScore = 0;
        this.powers = [];
        this.getUserWork();
      },
      updateCheckInfo(data) {
        console.log('268', data);
        if (data) {
          this.getWorkUserList();
        }
        return;
      },
      //切换附件题
      choosequest(item) {
        console.log('当前题目', item.questionId);
        console.log('当前题目', item);
        this.currentQuest = item;
        this.selectedQuestionId = item.answerId;
        // 评分分数和评星
        this.firstScore = item.score
        // if (item.ability) {
        //   Vue.set(item.ability, 'star', item.star);
        //   this.powers.push(item.ability)
        // }
        return;
      },
      async getWorkUserList() {
        if (!this.stageId || !this.gradeId || !this.roleId) return;
        let params = {
          stageId: this.stageId,
          gradeId: this.gradeId,
          roleId: this.roleId,
          userName: this.userName,
          answerType: 1,
          type: this.activeTab,
          page: this.pages.page,
          limit: this.pages.size,
        }
        let resData = await this.$Api.Activity.getWorkUserList(params);
        // console.log(resData);
        if (JSON.stringify(resData.data.list) == "[]" || !resData.data) {
          this.sutsList = [];
          this.pages.total = 0;
          return;
        }
        this.pages.total = resData.data.totalCount;
        this.sutsList = resData.data.list;
        this.selectedStudent = resData.data.list[0];
        this.getUserWork();
      },
      searchUser() {
        this.pages.page = 1;
        this.getWorkUserList();
      },
      changeSearchType(item) {
        this.activeTab = item.id
        this.getWorkUserList();
      },
      async getUserWork() {
        this.powers = [];
        this.subjectiveInfo = {};
        this.firstScore = null;
        let params = {
          stageId: this.stageId,
          roleId: this.roleId,
          userId: this.selectedStudent.id,
        }
        let resData = await this.$Api.Activity.getUserWork(params);
        console.log(resData);
        if (JSON.stringify(resData.data) == "[]" || !resData.data) {
          this.currentCheckInfo = {};
          return;
        }
        // 处理分数统计
        this.currentCheckInfo = resData.data
        for (const key in this.scoreMap) {
          this.scoreMap[key].value = this.currentCheckInfo[key]
        }
        // 评分分数和评星
        this.firstScore = resData.data.subjectiveDtos[0].score
        if (resData.data.subjectiveDtos[0].ability) {
          Vue.set(resData.data.subjectiveDtos[0].ability, 'star', resData.data.subjectiveDtos[0].star);
          this.powers.push(resData.data.subjectiveDtos[0].ability)
        }
        this.selectedQuestionId = resData.data.subjectiveDtos[0].answerId
        // this.selectedQuestionId = resData.data.answerId
        // 主观题上传的作品信息
        // this.subjectiveInfo = resData.data.subjectiveInfo;
        // if (JSON.stringify(this.subjectiveInfo.subjectiveImagesFiles) == '[]') return;
        // let imageList = []
        // this.subjectiveInfo.subjectiveImagesFiles.map((item) => {
        //   imageList.push(this.downloadURL + item.fileMd5)
        // })
        // Vue.set(this.subjectiveInfo, 'imageList', imageList)


        // 主观题
        this.subjectiveDtos = resData.data.subjectiveDtos;
        this.currentQuest = resData.data.subjectiveDtos[0];

      },
    },
    mounted() {
      this.selectedStudent = this.sutsList[0];
      this.getWorkUserList();
    },
  };
</script>

<style lang="less" scoped>
  .activity-selection {
    box-sizing: border-box;
    margin-bottom: 50px;
    align-items: flex-start;

    .selection-work-wrapper,
    .submit-list-wrapper {
      border: 1px solid #F2F2F2;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;
    }

    .selection-work-wrapper {
      margin-right: 24px;
      width: 651px;
      padding: 25px 27px;

      .selected-baseInfo {
        line-height: 1;

        .base-avatar {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          margin: 0 29px 0 16px;
        }

        .base-userName {
          font-size: 13px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .base-createTime {
          font-size: 12px;
          color: #666;
        }
      }

      .selected-score-count {
        height: 103px;
        background: rgba(204, 204, 204, .1);
        margin: 21px 0 36px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 10px;

        .score-item {
          display: flex;
          align-items: center;
          flex-direction: column;

          .score-num {
            color: #5572E8;
            font-size: 25px;
            font-weight: bold;

            span {
              font-size: 14px;
            }
          }

          .score-name {
            color: #333333;
            font-size: 12px;
          }
        }

        .score-item:nth-child(2) .score-num {
          color: #36C2CF;
        }

        .score-item:nth-child(3) .score-num {
          color: #F6B926;
        }

        .score-item:nth-child(4) .score-num {
          color: #EF5757;
        }

        .score-item:nth-child(5) .score-num {
          color: #B05BCF;
        }
      }

      .selected-correct {
        line-height: 1;

        .selected-correct-inner {
          max-height: 500px;
          overflow-y: auto;
          padding-right: 10px;
        }


        .selected-correct-title {
          margin-bottom: 19px;
          font-size: 15px;
          font-weight: 600;
        }

        .extra-file {
          margin-bottom: 30px;
          padding: 10px;
          border-radius: 5px;

          &.activeQuest,
          &:hover {
            background-color: rgba(204, 204, 204, 0.1);
          }

        }

        .extra-file-title {
          line-height: 24px;
          font-weight: 500;
          margin-bottom: 17px;
        }

        /deep/ .el-icon-tickets {
          color: #E66201;
        }

        .extra-image {
          width: 160px;
          height: 110px;
          margin-right: 10px;
          margin-bottom: 5px;
          border-radius: 5px;

          &:nth-child(2n) {
            margin-right: 0px;
          }
        }

        /deep/ .file-video-play .files {
          background-color: #FBFAFA;
          margin: 0 0 10px;
          max-width: 320px;
          overflow-x: auto;

          &::-webkit-scrollbar {
            height: 6px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(191, 191, 191, .5);
            border-radius: 15px;
          }
        }

        /deep/ .video-js {
          width: 330px !important;
          height: 180px !important;
        }
      }
    }

    .submit-list-wrapper {
      width: 319px;
      padding: 27px 27px 27px;

      .search-wrapper {
        color: #666;
        font-weight: 500;
        font-size: 13px;
      }

      .search {
        width: auto;

        /deep/.el-input__inner {
          border: 1px solid #CECECE;
          border-radius: 25px;
          width: 120px;
          height: 20px;
          line-height: 20px;
        }

        /deep/.el-input__icon {
          line-height: 20px;
        }
      }

      .list-sort {
        margin-top: 20px;
        border-bottom: 1px solid #F5F4F4;
        line-height: 1;

        &>li {
          position: relative;
          cursor: pointer;
          margin-right: 18px;
          padding: 0 12px;
          line-height: 25px;
          color: #999;

          &.active {
            color: #508EF9;

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              width: 100%;
              background: #508EF9;
              ;
            }
          }
        }
      }

      .submit-list {
        overflow: hidden;
        height: 98%;
        max-height: 750px;
      }

      .list-main {
        margin: 25px 0 40px;
        flex: 1;

        .student-item {
          padding: 5px 10px;
          margin-bottom: 20px;

          &.activeSelected {
            background-color: #f2f2f2;
            border-radius: 5px;
          }

          &:last-child {
            margin-bottom: auto;
          }
        }

        .student-userInfo {
          flex: 1;
        }

        .student-order {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          font-size: 12px;
        }

        .student-avatar {
          vertical-align: middle;
          margin-right: 15px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #D2D2D2;
        }

        .student-username {
          max-width: 120px;
          font-size: 12px;
        }
      }
    }


    @media screen and (min-width:1250px) {

      .selection-work-wrapper {
        width: 789px;
        padding: 30px 33px;

        .selected-baseInfo {

          .base-avatar {
            width: 40px;
            height: 40px;
            margin: 0 35px 0 19px;
          }

          .base-userName {
            font-size: 16px;
          }
        }

        .selected-correct {

          .selected-correct-inner {
            max-height: 500px;
            overflow-y: auto;
          }

          .extra-image {
            width: 200px;
            height: 150px;
            margin-right: 10px;
            margin-bottom: 5px;
          }

          /deep/ .file-video-play .files {
            max-width: 400px;
            overflow-x: auto;
          }

          /deep/ .video-js {
            width: 400px !important;
            height: 200px !important;
          }
        }
      }

      .submit-list-wrapper {
        width: 387px;
        padding: 33px 33px 35px;

        .search-wrapper {
          font-size: 16px;
        }

        .search {

          /deep/.el-input__inner {
            width: 150px;
            height: 24px;
            line-height: 24px;
          }

          /deep/.el-input__icon {
            line-height: 24px;
          }
        }

        .list-sort {

          &>li {
            margin-right: 22px;
            padding: 0 14px;
          }
        }

        .submit-list {
          max-height: 850px;
        }

        .list-main {

          .student-avatar {
            width: 42px;
            height: 42px;
          }

          .student-username {
            max-width: 150px;
            font-size: 14px;
          }
        }
      }
    }
  }

  /*修改滚动条样式*/
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 4px;
  }
</style>