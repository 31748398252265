<template>
  <div class="right-board">
    <!-- <div class="wrap2"> -->
    <h4 class="grade-title">评分:</h4>
    <div class="grade-item score">
      <span class="grade-item-name">本题得分：</span>
      <el-input placeholder="请输入得分" prefix-icon="el-icon-edit" size="mini" class="score-input" @input="impose(scoreVal)"
        v-model="scoreVal" :key="id" :disabled="gradeDisable"></el-input>
    </div>
    <!-- <div class="grade-item" v-if="JSON.stringify(power) != '[]' || !power">
      <span class="ability grade-item-name">能力评星：</span>
      <ul class="star-ul">
        <li class="star" v-for="(item, index) in power" :key="index">
          <span class="star-name wes-2">{{ item.name }}</span>
          <el-rate v-model="item.star"></el-rate>
        </li>
      </ul>
    </div> -->
    <!-- </div> -->
    <div class="btns flex-align-around">
      <button class="btn cancel" @click="cancel">取消</button>
      <button class="btn confirm" @click="subcheck">确认</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: Number, //问卷答题情况返回的ID，提交批改需要
      formid: String, //当前题目对应的表单id
      power: Array, //当前题目对应的能力标准
      stuInfo: Object, //当前学员的信息
      workid: Number, //当前问卷的id
      firstScore: Number, //第一题的得分
      themetype: Number, //作业主题类型
      gradeDisable: Boolean,
    },

    data() {
      return {
        scoreVal: null,
        initStar: null,
        checkArr: {
          comment: "", //每套问卷的点评
          score: "", //每套问卷的评分
          checkList: [] //每道题的批改详情
        }, //本地存储的批改数组，不包括批改人ID与问卷答题情况返回的ID
      };
    },
    methods: {
      //定义清空批改方法
      cancel() {
        if (!this.firstScore || !this.initStar) {
          return;
        }
        this.scoreVal = this.firstScore;
        this.power[0].star = this.initStar;
      },
      //提交批改
      async subcheck() {
        let params = {
          answerId: this.id,
          score: this.scoreVal,
          star: this.power[0].star
        }
        console.log("参数---", params);
        let resData = await this.$Api.Activity.scoreRecord(params);
        console.log(resData);
        if (resData.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功"
          });
          this.$emit('updateCheckInfo', true)
          return;
        }
        this.$message({
          type: "error",
          message: "提交失败，请稍后重试"
        });
      },
      //输入框限制
      impose(scoreVal) {
        if (isNaN(scoreVal)) {
          this.$message.error('请输入正确的分值');
          this.scoreVal = ''
        } else {
          //清除“数字”和“.”以外的字符
          this.scoreVal = scoreVal.replace(/[^\d.]/g, "");
          //只保留第一个. 清除多余的
          this.scoreVal = scoreVal.replace(/\.{2,}/g, ".");
          this.scoreVal = scoreVal.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
          //只能输入一个小数
          this.scoreVal = scoreVal.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3');
          //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02
          if (scoreVal.indexOf(".") < 0 && scoreVal != "") {
            this.scoreVal = parseFloat(scoreVal);
          }
          if (scoreVal < 0 || scoreVal > 100) {
            this.$message.error('分数不能小于0或者大于100');
            this.scoreVal = ''
          }
        }
      }
    },
    watch: {
      //监听批改情况变化
      id(newVal) {
        this.id = newVal
      },
      //监听formid变化
      formid(newVal) {
        this.formid = newVal
      },
      //监听能力标准变化
      power(newVal) {
        if (!newVal || JSON.stringify(newVal) == '[]') return;
        this.power = newVal
        console.log(this.power);
        this.initStar = newVal[0].star;
      },
      //监听学员信息情况变化
      stuInfo(newVal) {
        this.stuInfo = newVal
      },
      //监听第一题得分变化
      firstScore(newVal) {
        console.log('分数', newVal)
        // this.firstScore = newVal
        this.scoreVal = newVal
      }
    },
    mounted() {
      console.log('当前分数', this.firstScore);
      this.scoreVal = this.firstScore;
    },

  };
</script>

<style lang="scss" scoped>
  .right-board {
    margin-left: 25px;
    flex-shrink: 0;
    width: 240px;
    overflow-y: scroll;

    .grade-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .grade-item-name {
      flex-shrink: 0;
    }

    .score {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .score-input {
        margin-left: 23px;
      }
    }


    .star-ul {
      margin-top: 10px;

      .star {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .star-name {
          width: 50%;
          line-height: 18px;
          font-size: 13px;
          color: #666;
        }
      }
    }

    .btns {
      margin-top: 35px;

      .btn {
        padding: 0 33px;
        border-radius: 5px;
        line-height: 30px;
        font-size: 11px;
        border: 1px solid #508EF9;
        cursor: pointer;
      }

      .cancel {
        color: #508EF9;
      }

      .confirm {
        background-color: #508EF9;
        color: #fff;
      }
    }

    @media screen and (min-width:1250px) {
      margin-left: 40px;
      width: 280px;

      .btns {

        .btn {
          padding: 0 40px;
        }
      }
    }
  }

  .right-board::-webkit-scrollbar {
    display: none;
  }
</style>