<template>
    <div class="match-child-info flex-layout">
        <!-- 左侧选中的子活动信息 -->
        <div class="left-activity-detail">
            <el-form ref="form" :model="form" label-width="180px" v-if="pages.total > 0">
                <!-- 名称 -->
                <el-form-item label="子活动名称：" prop="name" required>
                    <p>{{currentStageInfo.name}}</p>
                </el-form-item>
                <!-- 环节类型 -->
                <el-form-item label="模式：" prop="isOnline" required>
                    <!-- 1 线上 0 线下 -->
                    <p>{{currentStageInfo.isOnline ?'线上':'线下'}}</p>
                </el-form-item>
                <!-- 子活动时间 -->
                <el-form-item label="子活动时间：" required prop="stageTime">
                    <p>{{currentStageInfo.stageStartTime}} - {{currentStageInfo.stageEndTime}}</p>
                </el-form-item>
                <!-- 模拟时间 -->
                <el-form-item label="模拟时间：" v-if="currentStageInfo.simulateStartTime">
                    <p>{{currentStageInfo.simulateStartTime}} - {{currentStageInfo.simulateEndTime}}</p>
                </el-form-item>
                <!-- 正式比赛 -->
                <el-form-item label="正式比赛时间：" v-if="currentStageInfo.formalStartTime">
                    <p>{{currentStageInfo.formalStartTime}} - {{currentStageInfo.formalEndTime}}</p>
                </el-form-item>
                <!-- 成绩查询时间 -->
                <el-form-item label="成绩查询时间：" required prop="queryTime">
                    <p>{{currentStageInfo.queryStartTime}} - {{currentStageInfo.queryStartTime}}</p>
                </el-form-item>
                <!-- v-if="form.formalTime || form.simulateTime" -->
                <div v-if="currentStageInfo.simulateStartTime|| currentStageInfo.formalStartTime">
                    <!-- 比赛时长 -->
                    <el-form-item label="比赛时长：" prop="matchDuration" required>
                        <p>{{currentStageInfo.matchDuration / 60}} 分钟</p>
                    </el-form-item>
                    <!-- 试卷规则 -->
                    <el-form-item label="试卷规则："></el-form-item>
                    <table class="testCount-table">
                        <tr>
                            <td>试题总量</td>
                        </tr>
                        <tr>
                            <td>{{currentStageInfo.testCount}}</td>
                        </tr>
                    </table>
                    <table class="testCount-table">
                        <tr>
                            <td :rowspan="2">客观题</td>
                            <td>单选题</td>
                            <td>多选题</td>
                            <td>判断题</td>
                        </tr>
                        <tr>
                            <td>{{currentStageInfo.singleCount}}</td>
                            <td>{{currentStageInfo.multipleCount}}</td>
                            <td>{{currentStageInfo.judgeCount}}</td>
                        </tr>
                    </table>
                    <el-form-item label-width="280px" label="考察能力：" required>
                        <p>比例</p>
                    </el-form-item>
                    <table class="testCount-table">
                        <tr>
                            <td>提取信息</td>
                            <td>形成解释</td>
                            <td>整体感知</td>
                            <td>做出评价</td>
                            <td>完成特定任务</td>
                        </tr>
                        <tr>
                            <td>{{abilityList['提取信息']}}</td>
                            <td>{{abilityList['形成解释']}}</td>
                            <td>{{abilityList['整体感知']}}</td>
                            <td>{{abilityList['做出评价']}}</td>
                            <td>{{abilityList['完成特定任务']}}</td>
                        </tr>
                    </table>
                    <table class="testCount-table">
                        <tr>
                            <td :rowspan="2">主观题</td>
                            <td>单子朗读</td>
                            <td>词语朗读</td>
                            <td>段落朗读</td>
                            <td>说话题</td>
                            <td>附件题</td>
                        </tr>
                        <tr>
                            <td>{{currentStageInfo.wordSingle}}</td>
                            <td>{{currentStageInfo.wordMultiple}}</td>
                            <td>{{currentStageInfo.wordParagraph}}</td>
                            <td>{{currentStageInfo.voiceCount}}</td>
                            <td>{{currentStageInfo.attachmentCount}}</td>
                        </tr>
                    </table>

                    <!-- 试题范围 -->
                    <div class="flex-layout">
                        <el-form-item label="试题范围：" prop="gradeIds" required>
                            <div v-for="(item,index) in allBooksList" :key="index" style="margin-bottom:20px">
                                <p>
                                    <span v-for="(each,i) in item.grade" :key="i">{{each}}
                                        <i v-if="i<item.grade.length-1">，</i> </span>
                                </p>
                                <p>
                                    <span v-for="(each,i) in item.book" :key="1000-i">{{each}}
                                        <i v-if="i<item.book.length-1">，</i> </span>
                                </p>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <!-- 是否显示成绩查询 -->
                <el-form-item label="是否显示成绩查询：" prop="isShowQuery" required>
                    <p>{{currentStageInfo.isShowQuery ?'是':'否'}}</p>
                </el-form-item>
                <!-- 是否显示大赛报告 -->
                <el-form-item label="是否显示大赛报告：" prop="isShowReport" required>
                    <p>{{currentStageInfo.isShowReport?'是':'否'}}</p>
                </el-form-item>
            </el-form>
            <Empty :show="pages.total == 0"></Empty>
        </div>
        <!-- 右侧子活动列表 -->
        <div class="right-list-container">
            <div class="flex-align-between right-title-box">
                <p class="right-title">子活动列表</p>
                <!-- <div class="csp" @click="$router.push(`/activity/create/stage/${$route.params.id}?breadNumber=5`)"> -->
                <div class="csp" @click="openCreateDialog">
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>添加子活动</span>
                </div>
            </div>
            <div v-if="stageList.length > 0">
                <ul class="activity-list">
                    <li v-for="(item,index) in stageList" :key="index" @click="getStageInfo(item.id)" class="list-item"
                        :class="{active:activeItemId == item.id}">
                        <!-- status 1未开始 2进行中 3已结束 -->
                        <p class="list-item-title" :class="statusMap[item.status]">{{item.name}}</p>
                        <div class="list-item-info">
                            <p>已完赛人数：{{item.finishCount}} 人</p>
                            <p>{{item.startTime}}-{{item.endTime}}</p>
                            <div class="toolbar flex-align-around" :class="statusMap[item.status]">
                                <span class="btn csp" @click="openEditDialog(item.id)">编辑</span>
                                <!-- @click="$router.push(`/activity/edit/stage/${item.id}?breadNumber=5`)">编辑</span> -->
                                <div></div>
                                <span class="btn csp" @click.stop="deleteStage(item.id)">删除</span>
                                <div></div>
                                <span class="btn csp" @click.stop="exportMember(item.id)">导入</span>
                            </div>
                        </div>

                    </li>
                </ul>
                <el-pagination :page-sizes="[5, 10, 15]" :page-size="pages.size" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" layout="prev, pager, next" :total="pages.total">
                </el-pagination>
            </div>
            <Empty :show="pages.total == 0" :text="'暂无子活动'"></Empty>
        </div>
        <!-- 创建弹窗 -->
        <el-dialog title="添加子活动" :visible.sync="showCreateDialog" width="1152px" :destroy-on-close="clearStatus">
            <createStage @closeDialog="closeCreateDialog"></createStage>
        </el-dialog>
        <!-- 编辑弹窗 -->
        <!-- destroy-on-close  -->
        <el-dialog title="编辑子活动" :visible.sync="showEditDialog" width="1152px" @close="closeEditDialog">
            <editStage @closeDialog="closeEditDialog" :stageId="editItemId" v-if="displayEdit"></editStage>
        </el-dialog>
        <!-- 导入 -->
        <el-dialog title="导入参赛成员" :visible.sync="showMemberDialog" width="40%" :before-close="closeExport">
            <exportMember :stageId="exportStageId" @closeExport="closeExport" @uploadFile="handleFile"
                v-if="displayExport"></exportMember>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    import createStage from './match/create_stage.vue'
    import editStage from './match/edit_stage.vue'
    import exportMember from './match/export-member.vue'
    export default {
        data() {
            return {
                displayEdit: false,
                activeItemId: undefined,
                activeItem: {},
                stageList: [],
                currentStageInfo: {},
                form: {},
                abilityList: {
                    '提取信息': 0,
                    '形成解释': 0,
                    '整体感知': 0,
                    '做出评价': 0,
                    '完成特定任务': 0,
                },
                bookList: [],
                gradeList: [],
                selectGrade: [],
                selectBook: [],
                statusMap: {
                    1: 'not-start',
                    3: 'finish',
                },
                showCreateDialog: false,
                clearStatus: false,
                showEditDialog: false,
                pages: {
                    page: 1,
                    size: 5,
                    total: 0,
                },
                allBooksList: [],
                openExport: false,
                exportStageId: undefined,
                showMemberDialog: false,
                displayExport: false,
            }
        },
        components: {
            createStage,
            editStage,
            exportMember
        },
        methods: {
            exportMember(id) {
                this.showMemberDialog = true;
                this.displayExport = true;
                this.exportStageId = id;
            },
            closeExport() {
                this.showMemberDialog = false;
                this.displayExport = false;
            },
            handleFile(data) {
                console.log('导入人员数据', data)
            },
            async getStageList() {
                let params = {
                    activityId: this.$route.params.id,
                    page: this.pages.page,
                    limit: this.pages.size
                }
                let resData = await this.$Api.Activity.getStageList(params)
                // console.log('子活动列表', resData);
                if (resData.code != 200) {
                    return;
                }
                if (resData.code == 200 && resData.data.list.length > 0) {
                    this.stageList = resData.data.list;
                    this.activeItem = resData.data.list[0];
                    this.activeItemId = resData.data.list[0].id;
                    this.pages.total = resData.data.totalCount
                    this.getStageInfo(this.activeItemId)
                }

                if (resData.data.list.length == 0) {
                    this.stageList = [];
                    this.activeItem = {};
                    this.activeItemId = undefined
                    this.pages.total = 0
                }

            },
            // 打开创建活动弹窗
            openCreateDialog() {
                this.showCreateDialog = true;
                this.clearStatus = false;
            },
            // 关闭创建活动弹窗
            closeCreateDialog(data) {
                this.showCreateDialog = data;
                this.clearStatus = true;
                this.getStageList();
            },
            // 打开编辑弹窗
            openEditDialog(id) {
                this.editItemId = id;
                this.displayEdit = true;
                this.showEditDialog = true;
                this.getStageInfo(id);
            },
            // 关闭编辑弹窗
            closeEditDialog(data) {
                this.showEditDialog = false;
                this.displayEdit = false;
                this.getStageInfo(this.editItemId);
            },
            //分页每页数量发生改变
            handleSizeChange(size) {
                this.pages.size = size;
                this.getStageList();
            },
            //分页当前页码发生改变
            handleCurrentChange(page) {
                this.pages.page = page;
                this.getStageList();
            },
            async getStageInfo(id) {
                let resData = await this.$Api.Activity.getGameInfo(id)
                // console.log('当前信息', resData);
                this.currentStageInfo = resData.data;
                this.activeItemId = id;
                let ablity = JSON.parse(resData.data.abilityProportion)
                if (ablity && ablity.length > 0) {
                    ablity.map(item => {
                        this.abilityList[item.name] = item.value
                    })
                }

                this.selectGrade = [];
                this.selectBook = [];
                // console.log('书籍信息', resData.data.gradeBookInfo);
                if (resData.data.gradeBookInfo && resData.data.gradeBookInfo.length > 0) {
                    this.getStageGrade().then(() => {
                        this.allBooksList = [];

                        resData.data.gradeBookInfo.map(item => {
                            // console.log(item);
                            let gradeIds = []
                            let bookIds = []
                            let gradeList = [];
                            let bookList = [];
                            item.map(each => {
                                if (!(gradeIds.find(ele => ele == each.gradeId))) {
                                    gradeIds.push(each.gradeId);
                                }
                                if (!(bookIds.find(ele => ele == each.bookId))) {
                                    bookIds.push(each.bookId);
                                }
                            })

                            this.gradeList.map(item => {
                                if (gradeIds.find(ele => ele == item.id)) {
                                    gradeList.push(item.name);
                                }
                            })
                            // console.log('年级', gradeList);
                            this.getSelectBookList(gradeIds).then(() => {
                                this.bookList.map(item => {
                                    if (bookIds.find(ele => ele == item
                                            .id)) {
                                        if (!(bookList.find(
                                                ele => ele == item
                                                .name))) {
                                            bookList.push(item
                                                .name);
                                        }

                                    }
                                })
                            })
                            // console.log('书籍', bookList);
                            this.allBooksList.push({
                                grade: gradeList,
                                book: bookList,
                            })
                        })
                        // console.log('全部书籍map', this.allBooksList)
                    });
                }

            },
            // 获取年级列表
            async getStageGrade() {
                let resData = await this.$Api.Activity.getStageGrade();
                // console.log('年级列表', resData)
                this.gradeList = resData.data;
                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 获取年级对应书籍列表
            async getSelectBookList(ids) {
                let data = {
                    gradeIds: ids,
                    projectId: this.currentProject.id
                }
                let resData = await this.$Api.Activity.getSelectBookList(data);
                // console.log('书籍列表', resData)
                this.bookList = resData.data;
                return new Promise((resolve, reject) => {
                    resolve(resData)
                })
            },
            // 删除子环节
            deleteStage(id) {
                // console.log("删除的活动id---", id);
                this.$confirm("是否删除该环节？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    })
                    .then(() => {
                        let params = {
                            id: id
                        }
                        this.$Api.Activity.delStageForMatch(params)
                            .then((res) => {
                                // console.log(res);
                                this.getStageList();
                                this.$message.success('删除成功')
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "取消成功",
                        });
                    });
            },
        },
        mounted() {
            this.getStageList();
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            }),

        },
    }
</script>

<style lang="less" scoped>
    .match-child-info {
        .left-activity-detail {
            flex: 1;
            border: 1px solid #F2F2F2;
            border-radius: 8px;
            padding: 20px 15px 50px 10px;

            .testCount-table {
                width: 450px;
                margin: 0px auto 15px;
                color: #333333;
                text-align: center;
                border-collapse: collapse;
            }

            table td {
                border: 1px solid #ccc;
                width: 100px;
                height: 30px;
            }
        }

        .right-list-container {
            flex-shrink: 0;
            width: 320px;
            margin-left: 16px;
            border: 1px solid #F2F2F2;
            border-radius: 8px;
            padding: 0 18px;
            box-sizing: border-box;
            align-self: flex-start;

            .right-title-box {
                line-height: 40px;
                border-bottom: 1px solid #F9F9F9;

                .el-icon-circle-plus-outline {
                    color: #508EF9;
                    margin-right: 11px;
                }
            }

            .activity-list {
                padding: 17px 0 50px;
                min-height: 785px;
            }

            .list-item {
                border: 1px solid #F2F2F2;
                border-radius: 8px;
                margin-bottom: 20px;
                overflow: hidden;

                &:hover,
                &.active {
                    box-shadow: 0px 3px 6px 0px rgba(60, 108, 195, 0.19);
                    border: 1px solid #cccccc;

                }

                .list-item-title {
                    line-height: 34px;
                    padding-left: 14px;
                    font-size: 13px;
                    color: #fff;
                    background: linear-gradient(96deg, #FF6464 0%, #FF5F5F 100%);
                    position: relative;

                    &::before,
                    &::after {
                        content: ' ';
                        position: absolute;
                        display: block;
                        top: 0px;
                        background-size: cover;
                    }

                    &::before {
                        left: 0;
                        width: 53px;
                        height: 33px;
                        background-image: url('~@/assets/images/work/title-bg.png');
                    }

                    &::after {
                        right: 0;
                        width: 43px;
                        height: 29px;
                        background-image: url('~@/assets/images/work/living.png');
                    }

                    &.not-start {
                        background: linear-gradient(98deg, #C567E7 0%, #C771E6 100%);

                        &::after {
                            background-image: url('~@/assets/images/work/unstart.png');
                        }
                    }

                    &.finish {
                        background: rgba(0, 0, 0, .1);
                        color: #666;

                        &::after {
                            background-image: url('~@/assets/images/work/end.png');
                        }

                    }
                }

                .list-item-info {
                    padding: 15px 16px 14px 16px;
                    line-height: 1;
                    font-size: 13px;

                    &>p {
                        margin-bottom: 12px;
                    }
                }

                .toolbar {
                    margin-top: 20px;
                    color: #FF5757;

                    div {
                        width: 1px;
                        height: 13px;
                        background: #F65454;
                    }

                    .btn {
                        &:hover {
                            color: rgba(255, 87, 87, .6)
                        }
                    }

                    &.not-start {
                        color: #C66DE7;

                        div {
                            background: #C66DE7;
                        }

                        .btn {
                            &:hover {
                                color: rgba(198, 109, 231, .6);
                            }
                        }
                    }

                    &.finish {
                        color: #666666;

                        div {
                            background: rgba(102, 102, 102, .1);
                        }

                        .btn {
                            &:hover {
                                color: rgba(102, 102, 102, .6);
                            }
                        }


                    }


                }
            }
        }

        /* 取消input的上下箭头 */
        /deep/ input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
        }

        /deep/ input::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
        }

        /deep/ input[type="number"] {
            -moz-appearance: textfield;
        }

        /deep/ .el-dialog {
            border-radius: 10px;

            .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 15px 31px 10px;
            }

            .el-dialog__title {
                font-size: 15px;
                color: #333;

                &::before {
                    content: '';
                    width: 5px;
                    height: 17px;
                    background-color: #01A2FD;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: -3px;
                    border-radius: 3px;
                }
            }

            .el-dialog__headerbtn {
                top: 15px;
            }

            .btns-box {
                text-align: center;
            }

            .submit {
                background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
                border-radius: 25px;
                color: #fff;
                line-height: 35px;
                padding: 0 63px;
                cursor: pointer;
                border: 0;

                &:hover {
                    background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
                }
            }
        }

        /deep/ .el-pagination {
            text-align: center;
            margin: 20px 0;
        }


        @media screen and (min-width:1250px) {}
    }
</style>